.Container{
    width: 100%;
    padding-top: 200px;
    color: white;
}
.Contain{
    max-width: 1600px;
    margin: 0 auto;
}

.fixedBackground{
    position: fixed;
    width: 100%;
    z-index: -1;
    min-height: 100vh;
  }

.HomeText{
    font-size: 32px;
    font-weight: 300;
    max-width: 460px;
    padding-left: 50px;
    line-height: 1.1;
}


.BoxOne{
 position: relative;
 top:110px;
}

.BoxTwo{
    position: relative;
    top:360px;
}

.BoxThree{
    position: relative;
    top:654px;
    margin-bottom:1000px;
}

.BoxFour{
    position: relative;
    top:405px;  
}

@media only screen and (max-width: 570px) {
    .HomeText{
        font-size: 24px;
        padding: 0px 20px;
    }
    .BoxThree{
        margin-bottom:700px;
    }
  }