//Navbar position
.navbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  margin: 0px 30px 0px 30px;
}
.fixedBackgroundContainer {
  position: fixed;
  height: 80px;
  width: 100%;
  overflow: hidden;
  z-index: -2;
}
.fixedBackground {
  width: 100%;
  z-index: -1;
  min-height: 100vh;
}

.navLinkRight {
  display: flex;
  align-items: center;
}

.navLinkRight a {
  position: relative;
  letter-spacing: 2px;
  margin-right: 70px;
}

.navLinkRight a:hover {
  font-weight: 600;
  letter-spacing: 1.7px;
}

.navLinksLeft {
  margin-left: 0px;
}

@media only screen and (max-width: 570px) {
  .navLinkRight {
    flex-direction: column;
    padding: 20px;
  }
  .navbarContainer {
    margin: 0;
    height: auto;
    background: rgba(0,0,0,0.46);
    box-shadow: 0px 0px 20px #00000029;
    backdrop-filter: blur(28px);
    -webkit-backdrop-filter: blur(28px);
    padding-top: 8px;
  }
  .navLinkRight a {
    margin-right: 0px;
  }
}
