// SmallBox

.SmallContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 25px;
  flex: 1 0 300px;
  max-width: 100%;
  min-height: 82px;
  max-height: 300px;
  height: fit-content;
  background: rgba(0,0,0,0.46);
  border-radius: 3px;
}

.Title {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.Text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 200;
}

.BoxContainer3TextAlign{
  
}

