@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Scrollbar Track */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar track */
}

/* Scrollbar Thumb */
::-webkit-scrollbar-thumb {
  background-color: #459cb7; /* Color of the thumb */
  border-radius: 0px; /* Rounded corners of the thumb */
}

/* Scrollbar Thumb on Hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #006685; /* Color of the thumb on hover */
}

/* Scrollbar Track on Hover */
::-webkit-scrollbar-track {
  background-color: #090f18; /* Color of the track */
}

/* Scrollbar Track on Hover */
::-webkit-scrollbar-track:hover {
  background-color: #090f18; /* Color of the track on hover */
}

@media screen and (max-width: 600px) {
  html::-webkit-scrollbar {
    width: 0;
  }
}
