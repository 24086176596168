.Container{
    width: 100%;
    height: auto;
    background-color: black!important;
}

.title h1{
    color: #fff;
    font-size: 60px;
}

.Contain{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto; 
    max-width: 1600px;
    margin: 0 auto;
    padding: 45px 100px 50px 100px;   
}

.boxContain{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:40px
}

.contactBoxOne{
    display: flex;
    flex: 1.5 ;
    flex-basis: 350px;
    max-width: 500px;
}

.contactBoxTwo, .contactBoxThree{
    flex: 1 ;
    max-width: 270px;
    flex-basis: 280px;
}

@media only screen and (max-width: 860px) {
    .contactBoxOne, .contactBoxTwo, .contactBoxThree{
       max-width: 100%;
    } 
    .Contain{
        padding: 20px;   
    }
  }

@media only screen and (max-width: 570px) {
    .contactBoxOne, .contactBoxTwo, .contactBoxThree{
       max-width: 100%;
    }
    .boxContain{
        justify-content: center;
    }
    .Contain{
        padding: 20px;   
    }
  }