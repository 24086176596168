.Container {
  min-width: 100%;
  padding: 20px;
  background: rgba(34, 94, 140, 0.37);
  box-shadow: 0px 0px 20px #00000029;
  backdrop-filter: blur(38px);
  -webkit-backdrop-filter: blur(38px);
}
.Container.BoxContainer3 {
  padding: 0px 20px;
}

.Contain {
  max-width: 1800px;
  margin: 0 auto;
}

.Contain.BoxContainer3 {
  background: rgba(0, 0, 0, 0.46);
  border-radius: 3px;
  padding: 80px 120px;
}

.InfoBoxTitle {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  padding: 0px 20px 40px 20px;
}

.InfoBoxContainer {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.InfoBoxContainer.BoxContainer3 {
  flex-wrap: nowrap;
}

.partenerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  flex: 1 0 300px;
  max-width: 100%;
  height: 170px;
  background: rgba(0, 0, 0, 0.46);
  border-radius: 3px;
}

.parternerText {
  display: flex;
  align-items: flex-end;
  font-size: 40px;
  margin: 50px 0px 0px 40px;
}

.partenerContainer img {
  width: 200px;
  height: auto;
}

.BoxContainer3TextAlign {
  max-width: 500px;
  padding-top: 200px;
}

.BoxContainer3Text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.harta {
  width: 100%;
  max-width: 640px;
  height: auto;
  transition: width 0.3s;
}

@media only screen and (max-width: 570px) {
  .partenerContainer img {
    width: 100px;
  }
  .parternerText {
    font-size: 20px;
    margin: 20px 0px 0px 40px;
  }
}

@media only screen and (max-width: 1200px) {
  .BoxContainer3TextAlign {
    max-width: 400px;
    padding-top: 160px;
  }
  .Contain.BoxContainer3 {
    padding: 40px 60px;
  }
}

@media only screen and (max-width: 900px) {
  .BoxContainer3TextAlign {
    max-width: 400px;
    padding-top: 60px;
  }
  .Contain.BoxContainer3 {
    padding: 40px 20px;
  }
}

@media only screen and (max-width: 900px) {
  .InfoBoxContainer.BoxContainer3 {
    flex-wrap: wrap;
  }

  .BoxContainer3TextAlign {
    max-width: 600px;
  }
}

@media only screen and (max-width: 720px) {
  .BoxContainer3TextAlign {
    max-width: 600px;
  }
  .Container.BoxContainer3 {
    padding: 0px 20px;
  }
  .BoxContainer3TextAlign {
    text-align: center;
  }
}
