.container h2 {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 20px;
}

input.custom-input,
select.custom-input,
textarea.custom-input {
  border: none;
  outline: none;
  background-color: black;
  border-radius: 0px;
  color: #ffffff !important;
  font-style: italic;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 2px solid #ffffff98;
  padding-left: 0px;
  transition: ease-in-out 0.3s;
}

input:hover.custom-input,
select:hover.custom-input,
textarea:hover.custom-input {
  border-bottom: 2px solid #ffffff;
}

input:focus.custom-input,
select:focus.custom-input,
textarea:focus.custom-input {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: black;
  border-bottom: 2px solid #ffffff;
}

.custom-input::placeholder {
  color: #ffffff;
  font-style: italic;
  font-weight: 300;
  opacity: 0.8;
}

.custom-input:hover::placeholder {
  font-weight: 500;
  opacity: 1;
}

.navbar {
  background-color: black !important;
  background-image: none!;
}

.custom-btn {
  min-width: 125px;
  height: 36px;
  font-size: 16px;
  font-weight: 600;
  background: #f3c855 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: none;
  transition: ease-in-out 0.3s;
  margin-top: 20px;
  color: #000000;
}

.custom-btn:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.custom-btn:focus {
  background: #9e7400 0% 0% no-repeat padding-box;
}
.custom-error {
  color: red;
  font-size: 12px;
}

.custom-error:focus {
  box-shadow: none;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
}

@media only screen and (max-width: 725px) {
  .custom-btn {
    width: 100%;
  }
}

.emailValidationmessage, .emailValidationmessage.statusOk  {
  color: #00c50a;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
}

.emailValidationmessage.statusError {
  color: #b30303;
}

/* Verification Area */
.verification-code {
  display: flex;
  padding: 20px 0px 0px 0px;
  gap: 30px;
  align-items: center;
  flex-wrap: nowrap;
}

.ToBeTypeCode {
 
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
}
.verificationBtn {
 
}

.verification-code .custom-input {
  width: 100%;
  text-align: center;
}


@media only screen and (max-width: 980px) {
  .verification-code .custom-input {
  font-size:12px;
  }
}

@media only screen and (max-width: 950px) {
  .verification-code {
    flex-wrap: wrap;
    justify-content: center;
  }
}
