.page-footer {
  background-color: black;
  height: 48px;
  padding: 10px 100px;
}

.footer-text {
  color: white;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerContent h6 {
  margin: 0px;
  font-size: 16px;
  font-weight: 200;
}

.logo-svg {
  width: 120px;
  height: auto;
}


@media only screen and (max-width: 768px) {
  .page-footer {
    padding: 10px 5px;
  }
  .logo-svg {
    width: 100px;
  }
}


@media only screen and (max-width: 570px) {
  .footerContent h6 {
    margin: 0px;
    font-size: 12px;
  }
  .logo-svg {
    width: 70px;
  }
}
